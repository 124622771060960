import React from 'react';
import SbEditable from 'storyblok-react';
import { Header } from './header';
import { HeroSection } from './herosection';
import { VideoSection } from './videoSection';
import {
	AnswerMultipleChoice,
	Footer,
	ImageLeft,
	ImageRight,
	LiveShowSection,
	MultipleChoiceSection,
	AnswerResultsSection,
	PrintSection,
	QuestionSection,
} from './';
import { SignUp } from './form/signup';

const Components = {
	'answer': AnswerResultsSection,
	'answer_hero': HeroSection,
	'answer-multiple-choice': AnswerMultipleChoice,
	'footer': Footer,
	'header': Header,
	'hero_section': HeroSection,
	'image-left-section': ImageLeft,
	'image-right-section': ImageRight,
	'liveshowSection': LiveShowSection,
	'multiple-choice-section': MultipleChoiceSection,
	'print-section': PrintSection,
	'question_section': QuestionSection,
	'range-question': QuestionSection,
	'sign-up': SignUp,
	'video_section': VideoSection,
	'video-section': VideoSection,
};

const DynamicComponent = ({ blok, ...rest }) => {
	if  (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component];
		return (
			<SbEditable content={blok}>
				<Component blok={blok} {...rest}/>
			</SbEditable>
		);
	}

	return <p>{blok.component} not implemented yet.</p>;
};

export default DynamicComponent;