import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { Text, Button } from '../';
import { SignUpIllustration } from '../svgs/signupIllustration';

export const SignUp = () => {
	const [email, setEmail] = useState('');
	const [school, setSchool] = useState('');
	const [zip, setZip] = useState('');
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [isSubmitting, setSubmitting] = useState(false);
	const [result, setResult] = useState('');

	const handleSubmit = event => {
		event.preventDefault();
		setSubmitting(true);
		setResult('');
		addToMailchimp(email, {
			FNAME: fname,
			LNAME: lname,
			ZIP: zip,
			SKOLE: school,
		})
			.then(({ result }) => {
				setResult(result);
			})
			.catch(() => {}) // mailchimp always returns 200 so nothing is caught
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<div className="flex flex-col justify-center min-h-screen bg-secondary py-87">
			<div className="mx-auto w-grid max-w-grid">
				<Text type="heading1" className="mb-32 text-primary">Tilmeld dig her:</Text>
				<Text type="pfSmall" className="mb-32 max-w-35 text-primary">Tilmeld dig Folkekirkens Nødhjælp nyhedsbrev til Live events - og få løbende nyheder omkring Live events indholdsprogram, datoer og klokkeslæt, så du kan planlægge det i din undervisning.</Text>
				<div className="flex items-center">
					{Boolean(!result.length || result === 'error') && (
						<form onSubmit={handleSubmit} id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" >
							<div className="w-580">
								<div>
									<label hmtlfor="mce-FNAME" className="block pl-8 font-playFair opacity-40 text-14">Navn*</label>
									<input type="text" onChange={e => setFname(e.target.value)} required name="FNAME" id="mce-FNAME" placeholder="Indtast dit navn" className="w-full px-8 py-4 rounded max-w-282 border-1 font-playFair bg-secondary border-primary"/>
									<input type="text" onChange={e => setLname(e.target.value)} required name="LNAME" id="mce-LNAME" placeholder="og efternavn" className="w-full px-8 py-4 ml-16 rounded max-w-282 border-1 font-playFair bg-secondary border-primary"/>
								</div>
								<div className="mt-16">
									<label hmtlfor="mce-EMAIL" className="block pl-8 font-playFair opacity-40 text-14">E-mail addresse*</label>
									<input type="email" onChange={e => setEmail(e.target.value)}required name="EMAIL" id="mce-EMAIL" placeholder="Indtast din e-mail adresse" className="w-full px-8 py-4 rounded border-1 font-playFair bg-secondary border-primary"/>
								</div>
								<div className="w-full mt-16">
									<label hmtlfor="mce-SKOLE" className="block pl-8 font-playFair opacity-40 text-14">Skole*</label>
									<input type="text" onChange={e => setSchool(e.target.value)} required name="SKOLE" id="mce-SKOLE" placeholder="Indtast navn på din skole..." className="w-full px-8 py-4 rounded border-1 font-playFair bg-secondary border-primary"/>
								</div>
								<div className="mt-16">
									<label hmtlfor="mce-ZIP" className="block pl-8 font-playFair opacity-40 text-14">Postnummer*</label>
									<input id="mce-ZIP" onChange={e => setZip(e.target.value)} type="number" required name="ZIP" placeholder="Indtast postnummer..." className="w-full px-8 py-4 rounded border-1 font-playFair bg-secondary border-primary"/>
								</div>
							</div>
							{result === 'error' && <div className="mt-16 text-16 text-accents-red font-playFair">Noget gik galt, prøv venligst igen.</div>}
							<Button variant="submitButtonLarge" type="submit" disabled={isSubmitting} colors="bw" className="mt-36 w-128">Send</Button>
						</form>
					)}
					{Boolean(result === 'success') && (
						<div className="self-start inline-block px-24 py-16 rounded-full text-white-100 font-playFair text-20 bg-primary">Din tilmelding er modtaget. Så snart der er nyt, hører du fra os på din e-mail.</div>
					)}
					<SignUpIllustration className="max-w-lg ml-auto w-33p"/>
				</div>
				<div className="max-w-3xl">
					<p className="mt-32 font-playFair text-12 text-white-100">Ved at registrere dig med Navn, E-mail, Skole og postnummer til Folkekirkens Nødhjælps digitale skoleevents, tillader du at Folkekirkens Nødhjælp anvender dine data til sende dig informationer om programmet for Folkekirkens Nødhjælps live events og indhold på deres quiz-platform. Folkekirkens Nødhjælp vil ligeledes bruge anonymiserede data til at lave statistik om deltagernes fordeling på geografi og klassetrin. Disse data deles med Udenrigsministeriet og Ole Kirks Fond, som har bevilliget midler til de digitale skolebesøg. Alle data vil blive slettet 1. juli 2022.</p>
					<p className="mt-32 text-white-100 blok font-playFair text-12">Spørgsmål til Live events sendes til Folkekirkens Nødhjælp: ahan@dca.dk
	Bemærk, Folkekirkens Nødhjælps digitale oplevelse med Live events lanceres d. 1 oktober og samtidig med undervisningsmaterialet på <a href="https://u-landskalender.dk/" target="_blank" rel="noreferrer">https://u-landskalender.dk/</a></p>
					<p className="mt-32 font-semibold text-white-100 font-playFair text-20">Det bliver spændende og lærerigt, og vi håber, at du er klar på rejsen!</p>

				</div>
			</div>
		</div>
	);
};
